<template>
  <div v-if="user_data" class="pa-12">
    <v-navigation-drawer
      light
      app
      color="white"
      :value="mainSidebar"
      class="elevation-0"
    >
      <div class="container center-align pb-0 mb-0">
        <img src="/logo-webmyday-black.webp" style="max-width: 100px" />
      </div>
      <div class="container center-align my-0 py-0">
          <v-menu
        bottom
        left
        offset-y
        open-on-hover
        transition="slide-y-transition"
        v-if="$vuetify.breakpoint.xs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            class="dashboard-card black--text"
            style="background: white"
            >
            <v-avatar size="30" left>
              <v-img :src="course.pic"></v-img>
            
              </v-avatar
            >
            Formation
            {{ course.name }}
            <v-icon right>mdi-chevron-down</v-icon></v-btn
          >
        </template>
        <v-list nav dense>
          <v-list-item
            link
            :to="{ name: 'welcome' }"
            color="#2962ff"
            @mouseover="hover_1 = '#2962ff'"
            @mouseleave="hover_1 = ''"
            class="navbar-item"
            active-class="selected-menu"
            title="Vers les autres formations"
          >
            <v-list-item-content>
              <v-list-item-title :style="{ color: hover_1 }">
                Vers les autres formations
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="hover_1">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
      <v-list nav class="pt-0 mt-0">
        <v-subheader
          style="
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            height: 38px;
          "
          class="pl-2 black--text mt-0"
        >
          Les essentiels
        </v-subheader>
         <!-- <v-list-item
          link
          :to="{ name: 'kick-off', params: { course: $route.params.course } }"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Bienvenue</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
          link
          :to="{ name: 'dashboard', params: { course: $route.params.course } }"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-apps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Tableau de bord</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{
            name: 'project-management',
            params: { course: $route.params.course },
          }"
          color="blue accent-4"
          style="font-size: 14px"
          active-class="current-link"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-format-list-checkbox</v-icon>
          </v-list-item-icon>
          <v-list-item-content style="overflow: visible !important">
            <v-list-item-title
              style="font-size: 14px; overflow: visible !important"
              class="font-weight-medium"
            >
              <v-badge
                color="error"
                :value="tasksToDo.length + tasksDoing.length"
                :content="tasksToDo.length + tasksDoing.length"
                class="mt-0"
                offset-x="-5"
                offset-y="8"
              >
                Tâches à réaliser
              </v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader
          style="
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            height: 38px;
          "
          class="pl-2 black--text"
        >
          Ma formation
        </v-subheader>
        <v-list-item
          link
          :to="{ name: 'academie', params: { course: $route.params.course } }"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-youtube</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Vidéos de formation</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{
            name: 'webinars',
            params: { course: $route.params.course },
          }"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-laptop-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Cours collectifs</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{
            name: 'ressources',
            params: { course: $route.params.course },
          }"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-book-open-page-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Ressources utiles</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>


        <!-- <v-list-item
          link
          :to="{
            name: 'ressources',
            params: { course: $route.params.course },
          }"
          color="blue accent-4"
          style="font-size: 14px"
          v-if="hasCertification($route.params.course)"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
              >Certification
              <v-spacer></v-spacer>
            <v-chip
              x-small
              label
              color="#E9EDFE"
              text-color="blue accent-4"
              class="font-weight-bold"
              >NEW</v-chip
            ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->

        <v-subheader
          style="
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            height: 38px;
          "
          class="pl-2 black--text"
        >
          Aller plus loin
        </v-subheader>

        <v-list-item
          link
          href="https://communaute-webmyday.slack.com/"
          target="_blank"
          color="blue accent-4"
          style="font-size: 14px"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-chat-question-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
            >
              La communauté
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{
            name: 'partnerships',
            params: { course: $route.params.course },
          }"
          color="blue accent-4"
        >
          <v-list-item-icon>
            <v-icon style="height: auto">mdi-ticket-percent</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              class="font-weight-medium"
            >
              Bons plans
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="px-2 pb-0">
          <v-btn
            block
            color="error"
            dark
            @click="logout"
            :loading="loading"
            text
          >
            <v-icon left>mdi-exit-to-app</v-icon>
            Déconnexion
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "@/firebase/init";

export default {
  name: "mainSidebar",
  computed: {
    ...mapState(["progress", "user_data", "mainSidebar", "course_id", "tasks", "course"]),
    tasksToDo: function () {
      return this.tasks.filter((task) => task.state === 1);
    },
    tasksDone: function () {
      return this.tasks.filter((task) => task.state === 3);
    },
    tasksDoing: function () {
      return this.tasks.filter((task) => task.state === 2);
    },
  },
  data() {
    return {
      items: [
        {
          text: "Tableau de bord",
          icon: "mdi-apps",
          link: {
            name: "dashboard",
            params: { course: this.$route.params.course },
          },
        },
        {
          text: "Suivi de projet",
          icon: "mdi-format-list-checkbox",
          link: {
            name: "project-management",
            params: { course: this.$route.params.course },
          },
        },
        {
          text: "Formation",
          icon: "mdi-youtube",
          link: {
            name: "academie",
            params: { course: this.$route.params.course },
          },
        },
        { text: "Ressources", icon: "mdi-folder-open" },
      ],
      selectedItem: 0,
      loading: false,
      loadingOverlay: false,
      hover_1: "",
      hover_2: "",
      hover_3: "",
      hover_4: "",
      hover_5: "",
      hover_6: "",
      hover_7: "",
      hover_8: "",
    };
  },
  methods: {
    // hasCertification(id){
      
    // },
    logout() {
      this.loading = true;
      auth
        .signOut()
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "Home" });
        })
        .catch((err) => console.log(err.message));
    },
    goToBuilder() {
      this.loadingOverlay = true;
      setTimeout(() => {
        this.$router.push({ name: "builder" });
      }, 2000);
    },
  },
  async mounted() {
    await this.$store.dispatch("setTasks");
  },
};
</script>

<style>
.sidebar-item {
  height: 70px !important;
}
.sidebar-item:hover::before {
  opacity: 0.12 !important;
  color: #3c6eff !important;
}

.sidebar-item-delete:hover::before {
  opacity: 0.12 !important;
  color: #ff5252 !important;
}

.sidebar-item-delete > div > i:hover {
  color: #ff5252 !important;
}

.sidebar-item-delete:hover {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}

.sidebar-item:hover {
  background-image: linear-gradient(#2862ff, #2862ff);
  background-repeat: no-repeat;
  background-size: 6px 100%, calc(100% - 4px) 100%;
  background-position: left, 4px 0;
}

.selected-menu {
  background-image: linear-gradient(#2862ff, #2862ff);
  background-repeat: no-repeat;
  background-size: 6px 100%, calc(100% - 4px) 100%;
  background-position: left, 4px 0;
}

.parent-item > div > div > i {
  height: auto !important;
}

.current-link > div > i {
  color: #2862ff !important;
}
</style>