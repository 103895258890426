<template>
    <v-container fluid class="pa-6">
         <v-row justify="center" class="mb-0">
            <v-col cols="12" class="d-flex flex-column" md="12">

                <v-card color="transparent" flat>
                    <v-card-title style="font-size:26px;">
                       {{ welcomeMessage }}
                    </v-card-title>
                    <v-card-subtitle class="body-2 pt-2" style="color: #88a6c9">Nous sommes le {{ today }}</v-card-subtitle>
                </v-card>
   
          </v-col>

         </v-row>

      <v-row>
      <v-col cols="9" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card px-4">
            <v-card-title class="text-body-1 font-weight-bold">
                Votre session de formation
              <v-spacer></v-spacer>
              
              <v-chip label outlined class="font-weight-regular" small><v-icon small left>mdi-calendar</v-icon>{{ currentSession.name }}</v-chip>
            </v-card-title>
            <v-card-text>
                <v-divider></v-divider>
              <v-row justify="center" style="min-height:200px;">
                <v-col cols="2" align-self="center">
                  {{ new Date(currentSession.start).toLocaleDateString() }}
                </v-col>
                <v-col align-self="center" cols="8">
                  <v-progress-linear value="20" color="blue accent-4" height="10"></v-progress-linear>
                </v-col>

                <v-col cols="2" align-self="center">
                   {{ new Date(currentSession.end).toLocaleDateString() }}
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="3" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card class="flex d-flex flex-column dashboard-card px-4">
            <v-card-title class="text-body-1 font-weight-bold">
               Une question ? Un problème ?
            </v-card-title>

            <v-card-text>
              <v-avatar size="80" class="elevation-1">
                <v-img src="https://webmyday.io/wp-content/uploads/2023/10/bea-interne.png"></v-img>
              </v-avatar>
              <p class="pt-2">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis asperiores possimus quo earum labore velit reiciendis eos, soluta repellat aliquid totam dolorum, repudiandae beatae accusamus id voluptate obcaecati ipsum dolor.</p>
            </v-card-text>
            <v-card-actions class="mb-1">
              <v-spacer></v-spacer>
              <v-btn text color="blue accent-4" small>Prendre rendez-vous <v-icon right>mdi-chevron-right</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KickOff",
  data() {
    return {
      dataLoaded: false,
      dataLoading: true,
      today : new Date().toLocaleDateString('fr-FR', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})
    };
  },
  computed: {
      ...mapState([
      "project",
      "user_data",
      "modules_v2",
      "progressModules",
      "coaches",
      "coach",
      "coachings",
      "tasks",
      "course",
      "webinars",
      "tickets",
      "userCourseInfo",
      "sessions"
    ]),
    firstName: function(){
      return this.user_data.profile.name.split(" ")[0]
    },
    welcomeMessage:function(){
      var today = new Date()
      var curHr = today.getHours()

if (curHr < 12) {
  return `Bonjour ${this.firstName} 👋`
} else if (curHr < 18) {
  return `Bon après-midi ${this.firstName}  👋`
} else {
  return `Bonsoir ${this.firstName} 👋`
}
    },
    currentSession: function(){
      return this.sessions.find((session) => session.id === this.userCourseInfo.session_id)
    }
  },
  methods: {

  },
    async mounted() {
    await this.$store.dispatch("setUserData");
    await this.$store.dispatch("setCourse", this.$route.params.course);
    await this.$store.dispatch("setCoaches");
    await this.$store.dispatch("setUserCourseInfo", this.$route.params.course);
    await this.$store.dispatch("setCoach", this.userCourseInfo.coach);
    await this.$store.dispatch("setModulesV2", this.$route.params.course);
    await this.$store.dispatch("setProgressModules", this.$route.params.course);
    await this.$store.dispatch("setTasks", this.$route.params.course);
    await this.$store.dispatch("setCoachings", this.$route.params.course);
    await this.$store.dispatch("setWebinars");
    await this.$store.dispatch("setTickets");
    await this.$store.dispatch("setSessions", this.$route.params.course);
    await this.$store.dispatch("updateStaticGlobalProgress", {
      course: this.$route.params.course,
      progress: Math.round(this.averageProgress),
    });
         this.dataLoaded = true;
    setTimeout(() => {
      this.dataLoading = false;
    }, 1400);

  },
}
</script>

<style>

</style>