<template>
  <v-container fluid class="px-4" v-if="user_data.profile">
    <v-row justify="center">
      <h1 class="display-1 text-center">👋 Ravis de vous revoir {{ user_data.profile.name.split(" ")[0] }}</h1>
    </v-row>
    <v-row justify="center">
      <p class="body-1 text-center">
        Sélectionnez votre parcours de formation ci-dessous 👇
      </p>
    </v-row>
    <v-row align="center" justify="start">
      <v-col v-for="(course, index) in filteredCourses" :key="index" md="3" cols="12">
        <v-card
          min-height="460"
          class="pa-1 dashboard-card test-card"
          :disabled="!course.online"
          flat
          :color="cardColor(isEnrolledInCourse(course.id))"
        >
         <v-container class="center-align">
          <v-row justify="center" class="mb-0">
            <v-img :src="course.pic" max-width="200"></v-img>
          </v-row>
          <div class="ribbon ribbon-top-right" v-if="!course.online">
            <span>Bientôt !</span>
          </div>
          <div class="ribbon ribbon-top-right" v-if="course.new">
            <span>New !</span>
          </div>
          <v-row justify="center" class="mb-0 pb-0">
            <v-card-title class="pb-0 font-weight-black">
            {{ course.name }}
          </v-card-title>
          </v-row>
          <v-row justify="center" class="pt-0 mt-0 mb-0 pb-0">
          <v-card-subtitle class="pt-0 font-italic">
            {{ course.title }}
          </v-card-subtitle>
          
        </v-row>

        <v-row justify="center" class="pt-0 mt-0" v-if="!isEnrolledInCourse(course.id)">
          <v-chip  small color="#4853FF" dark>
            <v-icon left small>mdi-sale</v-icon>
            -15% de réduction pour nos élèves !
          </v-chip>
        </v-row>

   
   
            <!-- <v-row class="pl-2 mt-0 pt-0">
              <v-rating
                v-model="course.rating.score"
                background-color="white"
                color="yellow accent-4"
                dense
                size="18"
                readonly
                half-increments
              ></v-rating>
              <span class="grey--text text--lighten-2 text-caption ml-2">
                ({{ course.rating.count }})
              </span>
            </v-row> -->
            <v-row justify="center" class="mt-0" no-gutters>
              <v-col cols="5">
                <v-chip small 
                        outlined
                        color="black">
                  <v-icon left size="18">mdi-clock</v-icon>
                  {{ course.hours }} heures
                </v-chip>
              </v-col>
              <v-col cols="5">
                <v-chip small
                        outlined
                        color="black">
                  <v-icon left size="16">mdi-file-document-multiple</v-icon>
                  {{ course.lessons }} leçons
                </v-chip>
              </v-col>
            </v-row>

        </v-container>
          <v-card-actions style="position:absolute;right:0;bottom:0">
            <v-btn
              text
              color="blue accent-4"
              v-if="isEnrolledInCourse(course.id)"
              @click="openCourse(course.id)"
              >Continuer <v-icon right>mdi-chevron-right</v-icon></v-btn
            >
            <v-btn text v-else target="_blank" :href="course.link"
              >En savoir plus <v-icon right>mdi-chevron-right</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase/init";
import firebase  from 'firebase';

export default {
  name: "Welcome",
  data() {
    return {
      hover: false,
      rating: 5,
      loading: false,
    };
  },
  computed: {
    ...mapState(["courses", "user_data"]),
      filteredCourses() {
    return this.courses.filter(course => this.isNotEnrolledInAcre(course.id));
  }
  },
  methods: {
    isNotEnrolledInAcre(id){
      if(id === 'wHJNNtFUHlMTHGEzS1im'){
        if(this.isEnrolledInCourse(id)){
          return true
        }
        else {
          return false
        }
      }
      else {
        return true
      }
    },
    cardColor(bool){
     return bool ? "white" : "grey lighten-5"
    },
    isEnrolledInCourse(course_id) {
      return this.user_data.profile.coursesEnrolled.includes(course_id);
    },
    openCourse(course_id) {
      this.loading = true;


      setTimeout(() => {
        db.collection(`users/${this.user_data.profile.user_id}/courses`).doc(course_id).update({
          loginHistory : firebase.firestore.FieldValue.arrayUnion({
              login: new Date(),
              logout: null,
              sessionLength: null

          })
        }).then(() => {
           this.$store.dispatch("setCourse", course_id).then(() => {
          this.$router.push({
            name: "dashboard",
            params: { course: course_id },
          });
        });
        })
       
      }, 1200);
    },
  },
  async mounted() {
    await this.$store.dispatch("setCourses");
  },
};
</script>

<style>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid black;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: black;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
</style>