<template>
  <div>
    <v-app-bar
      :color="navColor"
      height="95px"
      :flat="navColor === 'transparent'"
      fixed
      left
      app
      v-if="user_data.profile && project"
      style="box-shadow: 0 12px 50px 2px #13507c24"
    >
      <v-app-bar-nav-icon @click="toggle">
        <v-icon v-if="mainSidebar">mdi-menu-open</v-icon>
      </v-app-bar-nav-icon>

      <v-autocomplete
        prepend-inner-icon="mdi-magnify"
        background-color="white"
        class="dashboard-card mt-1"
        color="blue accent-4"
        placeholder="Chercher une leçon ou un concept"
        :items="allLessons"
        item-text="title"
        hide-details
        hide-selected
        v-model="search"
        :menu-props="{
          maxWidth: '580',
          rounded: true,
        }"
        clearable
        solo
        flat
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Aucun résultat pour cette recherche 🤯
              </v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-btn text color="blue accent-4" small :to="{ name: 'tickets' }"
                ><v-icon left>mdi-email</v-icon> Ouvrir un ticket</v-btn
              >
            </v-list-item-action> -->
          </v-list-item>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item  :to="{
                  name: 'lesson',
                  params: {
                    module: item.params.modul,
                    chapter: item.params.chap,
                    lesson: item.params.less,
                  },
                }">
            <v-list-item-content
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle
                v-text="item.chapterTitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="!$vuetify.breakpoint.xs"
                small
                color="blue accent-4"
                @click.stop
                text
                :to="{
                  name: 'lesson',
                  params: {
                    module: item.params.modul,
                    chapter: item.params.chap,
                    lesson: item.params.less,
                  },
                }"
                ><v-icon left>mdi-play-circle</v-icon>Voir la leçon</v-btn
              >
                <v-btn
                v-else
                small
                color="blue accent-4"
                @click.stop
                text
                :to="{
                  name: 'lesson',
                  params: {
                    module: item.params.modul,
                    chapter: item.params.chap,
                    lesson: item.params.less,
                  },
                }"
                ><v-icon left>mdi-play-circle</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>

      <v-menu
        bottom
        left
        offset-y
        open-on-hover
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="dashboard-card black--text mx-2 d-none d-sm-flex"
            style="background: white"
            >
            <v-avatar size="30" left>
              <v-img :src="course.pic"></v-img>
            
              </v-avatar
            >
            Formation
            {{ course.name }}
            <v-icon right>mdi-chevron-down</v-icon></v-btn
          >
        </template>
        <v-list nav dense>
          <v-list-item
            link
            :to="{ name: 'welcome' }"
            color="#2962ff"
            @mouseover="hover_1 = '#2962ff'"
            @mouseleave="hover_1 = ''"
            class="navbar-item"
            active-class="selected-menu"
            title="Vers les autres formations"
          >
            <v-list-item-content>
              <v-list-item-title :style="{ color: hover_1 }">
                Vers les autres formations
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="hover_1">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        left
        offset-y
        min-width="235"
        open-on-hover
        transition="slide-y-transition"
        v-if="course.id === 'a0d4QvJlvYY3KufEnk2a'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            style="background: white; max-width: 40px"
            class="mx-1 pa-0 dashboard-card d-none d-sm-flex"
            ><v-icon>mdi-tools</v-icon></v-btn
          >
        </template>

        <v-list nav dense>
          <v-subheader
            class="font-weight-black black--text text-uppercase mb-2"
          >
            Outils
            <v-spacer></v-spacer>
            <v-chip
              x-small
              label
              color="#E9EDFE"
              text-color="blue accent-4"
              class="font-weight-bold"
              >Nouveau !</v-chip
            >
          </v-subheader>
          <v-list-item
            link
            title="Lien vers le générateur de maquettes"
            @mouseover="hover_1 = '#2962ff'"
            @mouseleave="hover_1 = ''"
            class="navbar-item"
            active-class="selected-menu"
            @click="goToBuilder"
          >
            <v-icon left :color="hover_1">mdi-image-edit</v-icon>
            <v-list-item-title :style="{ color: hover_1 }"
              >Générateur de maquettes</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        left
        offset-y
        min-width="235"
        open-on-hover
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            style="background: white; max-width: 40px"
            class="mx-1 pa-0 dashboard-card d-none d-sm-flex"
            ><v-icon>mdi-account-group</v-icon></v-btn
          >
        </template>

        <v-list nav dense>
          <v-subheader class="font-weight-black black--text text-uppercase">
            Communauté
          </v-subheader>
          <v-list-item
            link
            color="#2962ff"
            title="Lien vers la communauté Webmyday"
            @mouseover="hover_1 = '#2962ff'"
            @mouseleave="hover_1 = ''"
            class="navbar-item"
            active-class="selected-menu"
            href="https://communaute-webmyday.slack.com"
            target="_blank"
          >
            <v-icon left :color="hover_1">mdi-slack</v-icon>
            <v-list-item-title :style="{ color: hover_1 }"
              >La Communauté</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            color="#2962ff"
            title="Déposer un avis sur ma formation"
            target="_blank"
            @mouseover="hover_2 = '#2962ff'"
            @mouseleave="hover_2 = ''"
            class="navbar-item"
            href="https://g.page/r/CUvOvrvLxnczEB0/review"
          >
            <v-icon left :color="hover_2">mdi-star-plus</v-icon>
            <v-list-item-title :style="{ color: hover_2 }"
              >Déposer un avis</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-divider style="background: #f0f7fd"></v-divider>
        <v-list nav dense>
          <v-subheader class="font-weight-black black--text text-uppercase">
            Réseaux sociaux
          </v-subheader>
          <v-list-item
            link
            color="#2962ff"
            title="Lien vers la page Facebook"
            @mouseover="hover_5 = '#2962ff'"
            @mouseleave="hover_5 = ''"
            class="navbar-item"
            target="_blank"
            href="https://www.facebook.com/WebMyDay.io"
          >
            <v-icon left :color="hover_5">mdi-facebook</v-icon>
            <v-list-item-title :style="{ color: hover_5 }"
              >Facebook</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            color="#2962ff"
            title="Lien vers la page Instagram"
            @mouseover="hover_6 = '#2962ff'"
            @mouseleave="hover_6 = ''"
            class="navbar-item"
            target="_blank"
            href="https://www.instagram.com/webmyday.io/"
          >
            <v-icon left :color="hover_6">mdi-instagram</v-icon>
            <v-list-item-title :style="{ color: hover_6 }"
              >Instagram</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            color="#2962ff"
            title="Lien vers la page LinkedIn"
            @mouseover="hover_7 = '#2962ff'"
            @mouseleave="hover_7 = ''"
            class="navbar-item"
            target="_blank"
            href="https://www.linkedin.com/school/webmyday-io"
          >
            <v-icon left :color="hover_7">mdi-linkedin</v-icon>
            <v-list-item-title :style="{ color: hover_7 }"
              >LinkedIn</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>


      <v-menu
        bottom
        left
        offset-y
        min-width="235"
        max-width="280"
        open-on-hover
        transition="slide-y-transition"
        class="dashboard-card d-none d-md-flex"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-large v-bind="attrs" v-on="on">
            <v-badge
              overlap
              dot
              color="green accent-4"
              bottom
              offset-x="10"
              offset-y="10"
              bordered
            >
              <v-avatar
                size="45"
                color="blue accent-4"
                class="white--text"
                v-if="!user_data.profile.picture"
              >
                {{ getInitials(user_data.profile.name) }}
              </v-avatar>
              <v-avatar size="45" v-else>
                <v-img :src="user_data.profile.picture"></v-img>
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>

        <v-list nav dense>
          <v-list-item two-line>
            <v-list-item-avatar v-if="user_data.profile.picture" size="45">
              <img :src="user_data.profile.picture" />
            </v-list-item-avatar>
            <v-list-item-avatar
              v-else
              size="45"
              color="blue accent-4"
              class="white--text"
            >
              {{ getInitials(user_data.profile.name) }}
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="font-weight-medium"
                style="font-size: 16px"
                >{{ user_data.profile.name }}</v-list-item-title
              >
              <v-list-item-subtitle
                class="font-weight-regular"
                style="color: rgb(136, 166, 201)"
                >{{ user_data.profile.email }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="mt-1">
                <v-chip
                  label
                  color="#E9EDFE"
                  text-color="blue accent-4"
                  class="font-weight-medium"
                  style="font-size: 12px"
                  small
                >
                  <v-icon left small>mdi-school</v-icon>
                  Élève
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-row justify="center" class="mb-0">
            <v-avatar size="45" v-if="user_data.profile.picture">
              <v-img :src="user_data.profile.picture"></v-img>
            </v-avatar>
            <v-avatar
              size="45"
              color="blue accent-4"
              class="white--text"
              v-else
            >
              {{ getInitials(user_data.profile.name) }}
            </v-avatar>
          </v-row>
          <v-row
            justify="center"
            class="font-weight-medium mb-0 mt-1"
            style="font-size: 16px"
          >
            {{ user_data.profile.name }}
          </v-row>

          <v-subheader class="my-0 py-0 justify-center">{{
            user_data.profile.email
          }}</v-subheader>

          <v-row justify="center" class="caption grey--text mb-4">
            <v-chip
              label
              color="#E9EDFE"
              text-color="blue accent-4"
              class="font-weight-medium"
              style="font-size: 12px"
              small
            >
              <v-icon left small>mdi-school</v-icon>
              Élève
            </v-chip>
          </v-row> -->
          <v-subheader class="font-weight-black black--text text-uppercase">
            Mon compte
          </v-subheader>
          <v-list-item
            link
            :to="{
              name: 'account',
              params: { course: this.$route.params.course },
            }"
            color="#2962ff"
            title="Mon compte"
            @mouseover="hover_1 = '#2962ff'"
            @mouseleave="hover_1 = ''"
            class="navbar-item"
            active-class="selected-menu"
          >
            <v-icon left :color="hover_1">mdi-cogs</v-icon>
            <v-list-item-title :style="{ color: hover_1 }"
              >Paramètres du compte</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-divider style="background: #f0f7fd"></v-divider>
        <v-list nav dense>
          <v-subheader class="font-weight-black black--text text-uppercase">
            Liens utiles
          </v-subheader>
          <v-list-item
            link
            color="#2962ff"
            title="Parrainer un proche"
            @mouseover="hover_5 = '#2962ff'"
            @mouseleave="hover_5 = ''"
            class="navbar-item"
            target="_blank"
            href="https://webmyday.io/parrainage"
          >
            <v-list-item-title :style="{ color: hover_5 }"
              >Parrainer un proche</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            color="#2962ff"
            title="Lien vers la communauté"
            @mouseover="hover_8 = '#2962ff'"
            @mouseleave="hover_8 = ''"
            class="navbar-item"
            href="https://communaute-webmyday.slack.com"
            target="_blank"
          >
            <v-list-item-title :style="{ color: hover_8 }"
              >Lien vers le Slack WebMyDay</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-divider style="background: #f0f7fd"></v-divider>
        <v-list nav dense>
          <v-list-item
            link
            color="error"
            @click="logout"
            title="Déconnexion"
            @mouseover="hover_7 = '#ff5252'"
            @mouseleave="hover_7 = ''"
            class="navbar-item-logout"
          >
            <v-icon left :color="hover_7">mdi-exit-to-app</v-icon>
            <v-list-item-title :style="{ color: hover_7 }"
              >Déconnexion</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { auth } from "@/firebase/init";
import { mapState } from "vuex";

export default {
  name: "mainNavbar",
  data() {
    return {
      hover_1: "",
      hover_2: "",
      hover_3: "",
      hover_4: "",
      hover_5: "",
      hover_6: "",
      hover_7: "",
      hover_8: "",
      hover_9: "",
      loading: false,
      navColor: "transparent",
      search: "",
      lessons: [
        { title: "Installer WordPress avec IONOS", link: "/academie/2/2/1" },
        { title: "Installer WordPress avec GoDaddy", link: "/academie/2/2/2" },
      ],
      loadingOverlay: false,
      isSystemBarShowed: true,
    };
  },
  computed: {
    ...mapState([
      "user_data",
      "project",
      "mainSidebar",
      "tickets",
      "modules_v2",
      "course",
    ]),
    unreadTickets: function () {
      return this.tickets.filter((ticket) => ticket.status === "done");
    },
    websiteURL: function () {
      if (this.user_data.configuration.isTemporaryDomain) {
        return `http://${this.user_data.configuration.url}`;
      } else {
        return `http://${this.project.progress.domain.address}`;
      }
    },
    allLessons: function () {
      let lessons = [];

      let modules = this.modules_v2;

      modules.forEach((modul, moduleIndex) => {
        modul.chapters.forEach((chap, chapIndex) => {
          chap.lessons.forEach((less, lessIndex) => {
            lessons.push({
              chapterTitle: chap.title,
              title: less.title,
              params: {
                modul: moduleIndex + 1,
                chap: chapIndex + 1,
                less: lessIndex + 1,
              },
            });
          });
        });
      });

      return lessons;
    },
    searchClass: function () {
      if (this.navColor === "transparent") {
        return "mt-2 mr-2";
      } else {
        return "mt-2 mr-2 elevation-2";
      }
    },
  },
  methods: {
    goToBuilder() {
      this.loadingOverlay = true;
      setTimeout(() => {
        this.$router.push({
          name: "builder",
          params: { course: this.$route.params.course },
        });
      }, 2000);
    },
    getCourseIcon(id) {
      if (id === "a0d4QvJlvYY3KufEnk2a") {
        return "mdi-wordpress";
      } else if (id === "wHJNNtFUHlMTHGEzS1im") {
        return "mdi-briefcase-outline";
      }
    },
    toggle() {
      this.$store.commit("TOGGLE_MAIN_SIDEBAR");
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    logout() {
      this.loading = true;
      auth
        .signOut()
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "Home" });
        })
        .catch((err) => console.log(err.message));
    },
    changeNavColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.navColor = "white";
      } else {
        this.navColor = "transparent";
      }
    },
  },
  async created() {
    await this.$store.dispatch("setCourse", this.$route.params.course);
  },
  mounted() {
    window.onscroll = () => {
      this.changeNavColor();
    };
  },
};
</script>

<style>
.navbar-item:hover::before {
  opacity: 0.12 !important;
  color: #3c6eff !important;
}

.navbar-item-logout:hover::before {
  opacity: 0.12 !important;
  color: #ff5252 !important;
}

.v-menu__content {
  box-shadow: 0 12px 50px 2px #13507c24 !important;
  border-radius: 8px;
}
</style>